import HttpServices from './index';
import { stringifyQueryUrl } from '../components/utils';

class SearchesService extends HttpServices {
    getAdvancedSearch = (query, path) => {
        const { paging, params } = query.payload;
        return this.http.post('/searches/advanced?' + stringifyQueryUrl(paging), params, this._setHeaderBoardsPath(path))}
    ;

    getBoards = (query, count, page, path = this.board_path) => this.http.get(`/searches/boards?itemsPerPage=${count}&page=${page}&query=${query}`, this._setHeaderBoardsPath(path));

    getSuggestion = (query, count = 'ALL', path = this.board_path) => this.http.get(`/searches/suggestions?itemsPerPage=${count}&query=${query}`, this._setHeaderBoardsPath(path));

    getSurnames = (query, count, page) => this.http.get(`/searches/surnames?itemsPerPage=${count}&page=${page}&seed=${query}`);

    getSurnamesByLetter = (query = '') => this.http.get(`/searches/surnames?itemsPerPage=TEN&page=1&seed=${query}`);
}

export default new SearchesService();

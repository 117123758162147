import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Field from '../../common/field';
import { AllClassification, AllPostedWithin } from '../constants';
import Form from '../../common/form';
import SimpleSearch from './simple-search';
import PageLinkModel from '../../common/old-pagination/model';
import { isBusy } from '../../../ducks/common/busy';
import { prefix, resetAdvancedSearchResults } from '../../../ducks/advanced-search';
import { parseBoolean } from '../../../utils/strings';
import QueryModel from '../../../ducks/common/query-model';
import { requestPopup } from '../../../ducks/popup';
import m from './messages';
import { FormattedMessage } from 'react-intl';
import { showPopup } from '../../../ducks/popup';
import { getNodePathData, resetNodePathState, loadingSelector } from '../../../ducks/node-path';

import useQuery from '../../../hooks/use-query-params';
import RadioLocation from '../../forms/search-results/fields/radio-location';
import Progress from '../../common/progress';


const renderOptions = dictionary => Object.keys(dictionary)
  .map(id => <option key={id} value={id}>{dictionary[id]}</option>);

export function AdvancedSearchForm(
  {
    busy,
    hasSimpleSearchForm,
    history,
    resetResults,
    showPopup,
    getNodePathData,
    resetNodePathState,
    loadingLocation
  }) {

  const { mode } = useParams();
    const {  
        keyword: queryParKeyword,
        subject: queryParSubject,
        author: queryParAuthor,
        surnames: queryParSurnames,
        useSoundex: queryParUseSoundex,
        classification: queryParClassification,
        postedWithin: queryParPostedWithin,
        path: queryPath,
        selectedPath: querySelectedPath
    } = useQuery();

  const [keyword, setKeyword] = useState('');
  const [subject, setSubject] = useState('');
  const [author, setAuthor] = useState('');
  const [surnames, setsurnames] = useState('');
  const [useSoundex, setUseSoundex] = useState(false);
  const [classification, setClassification] = useState('ALL');
  const [postedWithin, setPostedWithin] = useState('ALL');
  const [ checkedLocation, setCheckedLocation ] = useState(querySelectedPath);

  const handleChangeLocation = ({target: { value }}) => setCheckedLocation(value);

  const hasParams =  queryParKeyword || queryParSubject || queryParAuthor || queryParSurnames || queryParUseSoundex || queryParClassification || queryParPostedWithin;


  useEffect(() => {
    if (queryPath) {
      getNodePathData(queryPath)
    }
    return () => resetNodePathState()
  }, [])
  
  useEffect(() => {
    if (hasParams) {
      setKeyword(queryParKeyword || '');
      setSubject(queryParSubject || '');
      setAuthor(queryParAuthor || '');
      setsurnames(queryParSurnames || '');
      setUseSoundex(parseBoolean(queryParUseSoundex));
      setClassification(queryParClassification || 'ALL');
      setPostedWithin(queryParPostedWithin || 'ALL');
    }
  }, [hasParams]);


  useEffect(() => {
    setSubject('');
    setAuthor('');
    setsurnames('');
    setPostedWithin(postedWithin || 'ALL');
    setClassification(classification || 'ALL');
  }, [mode])
 
  const submitClass = classnames({
    loading: busy,
    ancBtn: true,
    orange: true
  });

  function submit() {
    const data = {
      keyword,
      subject,
      author,
      surnames,
      classification,
      postedWithin,
      useSoundex
    };
    Object.keys(data)
      .forEach(name => {
        const value = data[name];
        const isDropDownOption = ['classification', 'postedWithin'].indexOf(name) >= 0;
        const isEmptyValue = isDropDownOption ? value === 'ALL' : value === '';
        if (isEmptyValue) {
          delete data[name];
        }
      });
    if (!useSoundex) {
      delete data.useSoundex;
    }
    resetResults();

    // Handle case
    const pathParams = { fullForm: true };
    if (queryPath && querySelectedPath) {
      pathParams.path = queryPath
      pathParams.selectedPath = checkedLocation;
    }

    const submitQuery = new QueryModel({ params: {...data, ...pathParams} });
    if (hasSimpleSearchForm) {
      submitQuery.path = '/search/results';
    }
    
    history.push(submitQuery.toString());
  }

  function changeClassification(e) {
    setClassification(e.target.value);
  }

  const fields = {
    keyword: (
      <Field
        classes={keyword.trim() ? 'filled' : 'empty'}
        label={<FormattedMessage {...m.nameOrKeyword} />}
        name="keyword"
        setValue={setKeyword}
        type="search"
        value={keyword}
      />
    ),
    subject: (
      <Field
        classes={subject.trim() ? 'filled' : 'empty'}
        label={hasSimpleSearchForm ? <FormattedMessage {...m.subjectOfMessage} /> : <FormattedMessage {...m.subject} />}
        name="subject"
        setValue={setSubject}
        type="search"
        value={subject}
      />
    ),
    author: (
      <Field
        classes={author.trim() ? 'filled' : 'empty'}
        label={hasSimpleSearchForm ? <FormattedMessage {...m.authorOfMessage} /> : <FormattedMessage {...m.author} />}
        name="author"
        setValue={setAuthor}
        type="search"
        value={author}
      />
    ),
    surnames: (
      <Field
        classes={surnames.trim() ? 'filled' : 'empty'}
        label={hasSimpleSearchForm ? <FormattedMessage {...m.lastName} /> : <FormattedMessage {...m.surnames} />}
        name="surnames"
        setValue={setsurnames}
        type="search"
        value={surnames}
      />
    ),
    useSoundex: (
      <div className="field field-use-soundex">
        <input
          type="checkbox"
          className="checkbox"
          name="useSoundex"
          id="field-use-soundex"
          onChange={e => {
            setUseSoundex(e.target.checked);
          }}
          checked={useSoundex}
        />
        <label htmlFor="field-use-soundex"><FormattedMessage {...m.useSoundex} /></label>
      </div>
    ),
    classification: (
      <div className="field field-classification">
        <label htmlFor="field-classification"><FormattedMessage {...m.messageClassification} /></label>
        <select
          className={classification === 'ALL' ? 'empty' : 'filled'}
          id="field-classification"
          name="classification"
          onChange={changeClassification}
          value={classification}
        >
          {renderOptions(AllClassification)}
        </select>
      </div>
    ),
    // Uncomment this field when service will be work with him!
    // postedWithin: (
    //   <div className="field field-posted-within">
    //     <label htmlFor="field-postedWithin"><FormattedMessage {...m.postedWithin} /></label>
    //     <select
    //       className={postedWithin === 'ALL' ? 'empty' : 'filled'}
    //       id="field-postedWithin"
    //       name="postedWithin"
    //       onChange={e => setPostedWithin(e.target.value)}
    //       value={postedWithin}
    //     >
    //       {renderOptions(AllPostedWithin)}
    //     </select>
    //   </div>
    // )
  };

  const validateFields = () => {
    const textFields = [keyword, subject, author, surnames];
    let isModified = false;
    for (const el of textFields) {
      if (el !== '') {
        isModified = true;
        break;
      }
    }
    const isEmpty = !isModified && classification === 'ALL';
    return isEmpty;
  }

  const getFormFields = () => (
    <fieldset className="normal">
      <div className="row">
        {fields.keyword}
        {fields.subject}
      </div>
      {fields.author}
      {fields.surnames}
      {fields.useSoundex}
      <div className="row">
        {fields.classification}
        {fields.postedWithin}
      </div>
    </fieldset>
  )


  const tipLink = (
    <button type="button" className="link" onClick={showPopup}>
      <FormattedMessage {...m.boardFAQ} />
    </button>
  );

  if (loadingLocation) return <Progress />;

  const isEmpty = validateFields();

  return (
    <div className="ancGrid full320">
      <div className='ancCol w66'>
        <section className="con search-form">
          { queryPath && 
              <RadioLocation 
                locPathValue={queryPath} 
                checkedValue={checkedLocation} 
                handleChange={handleChangeLocation} 
                advFormPage 
              />
          }
          <Form submit={submit} classes="form" canSubmit={!busy}>
            <p className="tip">
              <strong><FormattedMessage {...m.tip} /></strong>
              <span><FormattedMessage {...m.tipText} values={{ link: tipLink }} />
            </span>
            </p>
              { getFormFields() }
              <div className="controls ancCol ancColRow formFooter w100">
                <button disabled={busy || isEmpty} className={submitClass}><FormattedMessage {...m.search} /></button>
              </div>
          </Form>
        </section>
      </div>
      <div className='ancCol w30'>
        <SimpleSearch classes="conSecond" />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    newQuery: new PageLinkModel(state.router.location.search),
    busy: isBusy(state, `${prefix}/`),
    loadingLocation: loadingSelector(state)
  };
}


function mapDispatchToProps(dispatch) {
  return {
    showPopup: () => dispatch(showPopup('FAQ')),
    getNodePathData: (path) => dispatch(getNodePathData(path)),
    resetNodePathState: () => dispatch(resetNodePathState()),
    resetResults: () => dispatch(resetAdvancedSearchResults()),
    showFAQ: () => dispatch(requestPopup('FAQ'))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdvancedSearchForm));

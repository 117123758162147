import { defineMessages } from 'react-intl';

export default defineMessages({
  authors: 'Authors',
  boards: 'Boards',
  categories: 'Categories ',
  confirmDelete: 'Are you sure you want to delete this favorite?',
  confirmDeleteYes: 'Yes',
  confirmDeleteCancel: 'Cancel',
  itemsCount: '1-5 of {number}',
  lastPost: 'Last Post',
  posts: 'Posts',
  repliesCount: 'Replies',
  seeAll: 'See all Favorites',
  threadAt: '{thread} ({board})',
  threads: 'Threads',
  title: 'My Favorites',
  tools: 'Tools',
  viewPosts: 'View Posts',
});

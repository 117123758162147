import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import m from './messages';


function BtnSearch({ type, disable=false }) {

  return (
    <button className='ancBtn' type={type} disabled={disable}>
      <FormattedMessage {...m.searchBtn} />
    </button>
  )
}

BtnSearch.propTypes = {
  type: PropTypes.string,
  disable: PropTypes.boolean
}

export default BtnSearch;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import useQuery from '../../../hooks/use-query-params';
import { mapObjToQueryString } from '../../utils';
import { FormattedMessage } from 'react-intl';
import m from './messages';

import {
  // actions
  setPage,
  // selectors
  currentPageSelector, 
} from '../../../ducks/search-adv-result';

export default function Pages({ hasItems, paging }) {
  if (!hasItems && !paging) return null;
  // routes data
  const history = useHistory();
  const dispatch = useDispatch();

  const parsedQueryInObj = useQuery();
  const { currentPage: urlPage, totalPages } = paging || {};
  const { pathname } = useLocation();
  // state
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const statePage = useSelector(currentPageSelector);

  const currentPage = pathname === '/search/results' ? statePage : urlPage;

  if (!currentPage || !totalPages) return null;


  if (parsedQueryInObj.page > totalPages) {
    const queryUrl = mapObjToQueryString(parsedQueryInObj, { page: totalPages });
    const fullUrl = totalPages === 1 ? pathname : `${pathname}${queryUrl}`;
    return <Redirect to={fullUrl} />
  }

  useEffect(
    () => {
      if (currentPage <= 1) setDisabledPrev(true)
      else if (currentPage >= 1 && disabledPrev) setDisabledPrev(false)

      if (currentPage >= totalPages) setDisabledNext(true)
      else if (currentPage <= totalPages && disabledNext) setDisabledNext(false)
    }
  )


  const handleButtonClick = (type) => () => {
    if (type === 'prev' && disabledPrev) return;
    if (type === 'next' && disabledNext) return;
    const nextPage = (type === 'prev') ? currentPage - 1 : currentPage + 1;
    if (pathname === '/search/results') {
      dispatch(setPage(nextPage));
    } else {
      const queryUrl = mapObjToQueryString(parsedQueryInObj, { page: nextPage })
      const fullUrl = `${pathname}${queryUrl}`;//
      history.push(fullUrl);
    }
  }

  const formattedTotalPages = totalPages.toLocaleString('en', { maximumSignificantDigits: 21 });
  return (
    <div className='paging__pages'>
      <button
        aria-label='previous'
        className={`paging__btn paging__btn--prev${disabledPrev ? ' disabled' : ''}`}
        disabled={disabledPrev}
        onClick={handleButtonClick('prev')}
      >
        <FontAwesomeIcon className='paging__btn-icon' icon={faChevronLeft} />
      </button>
      <span className='paging__info'>{<FormattedMessage {...m.pageRange} values={{ currentPage, formattedTotalPages }} />}</span>
      <button
        aria-label='next'
        className={`paging__btn paging__btn--next${disabledNext ? ' disabled' : ''}`}
        disabled={disabledNext}
        onClick={handleButtonClick('next')}
      >
        <FontAwesomeIcon className='paging__btn-icon' icon={faChevronRight} />
      </button>
    </div>
  )
}


Pages.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  itemsPerPage: PropTypes.number
}

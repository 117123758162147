import React, { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Progress from './progress';
import m from './messages';
import { useHistory } from 'react-router-dom';

import {
  // actions
  closePopup, showPopup,
  //selectors
  isOpenedSelector, loadingSelector, contentSelector, classValueSelector, errorSelector
} from '../../ducks/popup';

function useHookWithRefCallback(dispatch) {
  const ref = useRef(null);
  const history = useHistory();

  const setRef = useCallback(node => {
    const node$ = (node) ? node : ref.current;
    const click$ = node$.querySelectorAll('[href*="<%= BoardSettings.BoardsUrlPrefix %>"]');
    //Defect DE75761
    const anchorTags = node$.querySelectorAll('a,button'); //[href*="#"]
    //
    if (ref.current) {
      click$.forEach((link) =>
        link.removeEventListener('click', handleLinkClick)
      );
      //Defect DE75761
      anchorTags.forEach((anchorTag) => {
        anchorTag.removeEventListener('click', handleHashLinkClick);
        anchorTag.removeEventListener('keydown', handleKey);
      });
      //
    }

    if (node) {
      click$.forEach((link) =>
        link.addEventListener('click', handleLinkClick)
      );
      //Defect DE75761
      anchorTags.forEach((anchorTag) => {
        anchorTag.addEventListener('click', (event) => {
          handleHashLinkClick(event, anchorTags);
        });
        anchorTag.addEventListener('keydown', (event) => {
          handleKey(event, anchorTags);
        });
      });
      //
    }
    // Save a reference to the node
    ref.current = node
  }, []);

  //Defect DE75761
  const handleHashLinkClick = (event, linkTags) => {
    const link = event.currentTarget;
    const linkid = link.hash.split("#")[1];
    const linkTag = document.querySelector('[id="' + linkid + '"]');
    const targetElement = findLinkTagFromSiblingElement(linkTag);
    let isTargetEle = false;
    linkTags.forEach((element, index) => {
      if (element !== targetElement && !isTargetEle) {
        element.setAttribute("tabindex", -1);
      } else {
        isTargetEle = true;
        element.setAttribute("tabindex", 0);
      }
    });
    if (!isTargetEle) {
      linkTags[linkTags.length - 1].setAttribute("tabindex", 0);
    }
  }

  const handleKey = (event, anchorTags) => {
    if (event.keyCode === 9) {
      let shiftKey = event.shiftKey;
      let preNode = null;
      anchorTags.forEach((ele, i) => {
        if (event.currentTarget === ele) {
          preNode = i > 0 ? anchorTags[i - 1] : anchorTags[i];
        }
      })
      if (shiftKey) {
        preNode.setAttribute("tabindex", 0);
      }
    }
  }
  //

  const handleLinkClick = (event) => {
    const link = event.currentTarget
    const href = link.getAttribute('href')
    const target = link.getAttribute('target')
    const url = new URL(href || '', window.location.origin)

    const isInternalLink = url.origin === window.location.origin
    const isOpenedInSameWindow = !target || target === '_self'
    const isLeftButtonClick = event.button === 0

    const hash = link.hash;
    const popupname = findPageName(link);

    const isModifierKeyPressed =
      event.altKey || event.ctrlKey || event.metaKey || event.shiftKey

    if (
      isInternalLink &&
      isOpenedInSameWindow &&
      isLeftButtonClick &&
      !isModifierKeyPressed
    ) {
      event.preventDefault();
      dispatch(closePopup());
      staticModalRedirect({ popupname, hash });
    }
  }

  const findPageName = (link) => {
    if (link.pathname.includes('boardfaq.aspx')) {
      return 'FAQ';
    } else if (link.pathname.includes('boardhelp.aspx')) {
      return 'HELP';
    }
  }

  const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const staticModalRedirect = async (args) => {
    await delay(200);
    dispatch(showPopup(args.popupname));
    if (typeof args.hash === 'string' && args.hash !== "") {
      const response = await retryFunc(findNodeAsyncFunction.bind(this, args, 1000), 5);
      const result = await response();
      result.node[0].click();
      return;
    }
    history.push(args.hash);
  }

  return [setRef]
}

const retryFunc = (fun, reties) => {
  let attempt = 0;
  let doTry = (...args) => {
    attempt++;
    return fun(...args)
      .catch((err) => {
        if (attempt <= reties) {
          return doTry(...args);
        } else {
          return Promise.reject(err);
        }
      });
  }
  return doTry;
}

const findNodeAsyncFunction = (args, delay) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const node$ = document.querySelectorAll("div.con [href*='" + args.hash + "']");
      (node$.length > 0) ? resolve({ node: node$ }) : reject({ node: node$ });
    }, delay);
  });
}

// Defect DE75761
const findLinkTagFromSiblingElement = (node) => {
  let anode = null;
  if (node) {
    if (node.childElementCount === 0) {
      return findLinkTagFromSiblingElement(node.nextElementSibling);
    } else {
      let isNodeAvailable = false;
      for (let i = 0; i < node.childElementCount; i++) {
        if (node.children[i].nodeName === 'A' || node.children[i].nodeName === 'BUTTON' && !isNodeAvailable) {
          anode = node.children[i];
          isNodeAvailable = true;
        } else {
          let childOfChild = node.children[i];
          if (childOfChild.childElementCount > 0) {
            for (let cc = 0; cc < childOfChild.childElementCount; cc++) {
              console.log("childOfChild", childOfChild.children[cc]);
              if (childOfChild.children[cc].nodeName === 'A' || childOfChild.children[cc].nodeName === 'BUTTON' && !isNodeAvailable) {
                anode = childOfChild.children[cc];
                isNodeAvailable = true;
              }
            }
          }
        }
      }
      if (!anode) {
        return findLinkTagFromSiblingElement(node.nextElementSibling);
      }
    }
    return anode;
  }
}
//

export default function Popup(props) {
  const dispatch = useDispatch();
  const isOpen = useSelector(isOpenedSelector);
  const content = useSelector(contentSelector);
  const loading = useSelector(loadingSelector);
  const classValue = useSelector(classValueSelector);
  const error = useSelector(errorSelector);
  const [ref] = useHookWithRefCallback(dispatch);

  const handleClosePopup = () => dispatch(closePopup());

  const getBody = () => {
    if (loading) return <Progress />;
    if (error) return <div>Oops, something happend wrong</div>
    return <div ref={ref} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  const getOtherProps = () => {
    const props = {};

    if (classValue) props.className = classValue;

    return props;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClosePopup}
      {...getOtherProps()}
    >
      <DialogContent>
        {getBody()}
      </DialogContent>
      <DialogActions>
        {!loading && <Button color="primary" onClick={handleClosePopup}><FormattedMessage {...m.closeModal} /></Button>}
      </DialogActions>
    </Dialog>
  )
}
import React, { useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import m from './messages';
import Section from '../../common/old-section';

export default function SimpleSearch({ classes = '' }) {
  const [searchText, setSearchText] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const encodedSearchText = searchText ? encodeURIComponent(searchText.trim()) : '';
  const isValid = searchText.length >= 2;
  const error = !isChanged || isValid ? null : (
    <div id="errmsg" className="errorMessage icon iconWarning"><FormattedMessage {...m.simpleSearchWarning} /></div>
  );

  const validationClassName = classnames({ error: isChanged && !isValid });
  const searchLink = `/search/boards/${searchText}`
  const searchLinkClassNames = classnames('ancBtn noLabel', {
    orange: isValid,
    disabled: !isValid
  });

  const ariaProps = {
    ...(!isValid && {
      'aria-disabled': 'true',
      'tabindex': '-1'
    })
  }

  return (
    <Section title={<FormattedMessage {...m.simpleSearchTitle} />} classes={classes}>
      <div className="search-row">
        <div className={`${isValid ? 'filled' : 'empty'}`}>
          <label htmlFor="simple-search-text" className={validationClassName}>
            <FormattedMessage {...m.surnamesOrTopics} />
          </label>
          <input
            type="search"
            id="simple-search-text"
            aria-describedby='errmsg'
            className={validationClassName}
            value={searchText}
            onChange={e => { setSearchText(e.target.value); setIsChanged(true); }}
          />
          {error}
        </div>
        <div className="ancCol">
          <Link to={isValid ? searchLink : '#'} className={searchLinkClassNames} {...ariaProps} >
            <FormattedMessage {...m.go} />
          </Link>
        </div>
      </div>
    </Section>
  );
}

SimpleSearch.propTypes = {
  classes: string
};

SimpleSearch.defaultProps = {
  classes: ''
};
